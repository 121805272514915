.ProductViewer {
	display: flex;
	position: relative;
	overflow: hidden;
}

.no-permission {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #2d5d9f;
	font-size: 20px;
	width: 85%;
	text-align: center;
}

.viewerleft {
	width: 100%;
	height: 100%;
}

.viewerleft canvas,
.viewerleft > div {
	max-width: 100%;
}

.viewerright {
	width: 50%;
	height: 100%;
	display: none;
}

.popUpForIpr {
	position: absolute;
	background-color: #333;
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	font-family: "Arial", sans-serif;
	font-size: 14px;
	text-align: center;
	opacity: 0.9;
	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
	top: -46px;
	right: 50%;
	translate: 50%;
	width: max-content;
}

.popUpForIpr:hover {
	opacity: 1;
	transform: scale(1.1) rotate(10deg);
}

.addIprInput {
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
}

.labelRemoveButton {
	background-color: #ff1800;
	color: #ffffff;
	font-family: "Arial", sans-serif;
	font-size: 12px;
	border: none;
	cursor: pointer;
	position: absolute;
	left: calc(100% + 8px);
	height: 23px;
	padding: 4px 4px;
	width: 22px;
	border-radius: 6px;
	text-align: center;
	margin-left: 0px;
}

.labelIprNo {
	background-color: var(--primary-color3);
	color: #ffffff;
	padding: 4px 8px;
	border-radius: 6px;
	box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.25);
	text-align: center;
}
