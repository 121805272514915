.Footer-Section {
	background: var(--primary-color);
	/* box-shadow: 0px -11px 32px 0px rgba(0, 0, 0, 0.16); */
	height: 70px;
	padding: 8px 15px;
	/* display: flex;
    align-items: center; */
}

.Footer-Section-Outer {
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	flex-shrink: 0;
}

.Footer-Section-ButtonAction {
	display: flex;
	align-items: center;
	gap: 20px;
}

.Footer-Section-ButtonAction button {
	border: 2px solid var(--primary-color);
	background: var(--primary-color);
	padding: 5px 8px;
	border-radius: 5px;
}

.Footer-Section-ButtonAction button:hover {
	background: var(--primary-color4);
}

.Footer-Section-ButtonAction .ButtonAction-Polygon img {
	width: 12px;
}

.Footer-Section-ButtonAction .ButtonAction-Unicon img {
    width: 16px;
}

.Footer-Section-Speed {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
    margin: 0 10px;
}

.Speed-Text {
	color: var(--primary-color3);
	/* font-family: Inter; */
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.Footer-Section-CountBtn {
	overflow-x: auto;
	display: flex;
	gap: 10px;
	max-width: calc(100vw - 430px);
	flex-wrap: nowrap;
	overflow-x: auto;
}

.Footer-Section-CountBtn button {
	display: flex;
	padding: 8px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 4px;
	border: 1px solid var(--primary-color4);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.Footer-Section-CountBtn button:hover {
	background: transparent;
	color: var(--primary-color3);
	border-color: var(--primary-color3);
}

.Footer-Section-CountBtn button.active {
	background: var(--primary-color4);
	color: var(--primary-color3);
}

.Footer-Section-CountBtn .line {
	height: 3px;
	width: 40px;
	display: block;
	background-color: #cdcdcd;
	pointer-events: none;
}

.Footer-Section-CountBtn .line.active {
	background-color: var(--primary-color3);
}
