.HeaderNav-section {
	display: flex;
	/* justify-content: space-between; */
	align-items: center;
	flex-wrap: wrap;
	column-gap: 20px;
	padding: 10px 15px;
	background: var(--primary-color);
	box-shadow: -1px 16px 32px 0px rgba(0, 0, 0, 0.08);
	position: relative;
	/* height: 140px; */
}

/* .HeaderNav-section button {
	height: 50px;
} */

.notLoggedIn .HeaderNav-section.withEditor {
	justify-content: space-between;
}

.HeaderNav-section .modal button {
	height: auto;
}

.HeaderNav-section .modal button.btn-close {
	height: 1em;
}

.HeaderNav-section .modal button.btn-close:hover {
	background-color: transparent;
}

/* .HeaderNav-section.withEditor {
	padding-bottom: 0;
} */

.HeaderNav-Menu-Inner {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
}
.headerMain{
	display: none !important;
}

.addIprHeader{
	justify-content: space-between !important;
}

.HeaderNav-Menu-Inner button {
	border-radius: 0px;
	border: none;
	border-bottom: 5px solid transparent;
	background: transparent;
	display: flex;
	padding: 5px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
	height: 70px;
}

.HeaderNav-logo img {
	max-width: 160px;
	height: 60px;
}

.HeaderNav-Menu-Inner button.active path span.Menu-List-Text {
	background: var(--primary-color3);
	fill: var(--primary-color);
	color: var(--primary-color);
}

.HeaderNav-Menu-Inner button:hover {
	background: var(--primary-color4);
}

/* .HeaderNav-Menu-Inner button:hover path {
    fill: var(--primary-color4);
}   */

/* .HeaderNav-Menu-Inner button:hover span.Menu-List-Text {
    color: var(--primary-color);
} */

span.Menu-List-Text {
	color: var(--primary-color3);
	/* font-family: Inter; */
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	/* text-transform: uppercase; */
}

.HeaderNav-Right-Inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}

select.form-select {
	border-radius: 6px;
	/* background: var(--primary-color4); */
	display: inline-flex;
	padding: 10px 40px 10px 15px;
	justify-content: center;
	align-items: center;
	border: none;
	color: var(--primary-color3);
	/* font-family: Inter; */
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	background-image: url("/public/assets/img/downarrow.svg");
	background-repeat: no-repeat;
	background-position: right 0.75rem center;
	background-size: 16px 12px;
	max-width: 170px;
	min-width: 170px;
	/* width: 175px; */
	/* height: 50px; */
	cursor: pointer;
	border: 1px solid var(--primary-color3);
}

.treatment-ipr-data {
	border: 1px solid var(--primary-color3);
	border-radius: 6px;
	padding: 10px 15px;
	color: var(--primary-color3);
	font-weight: 400;
	font-style: normal;
	font-size: 14px;
	line-height: normal;
}
.homeIcon{
	/* padding:7px 12px; */
	border-radius: 5px;
    background-color: var(--primary-color4);
    color: var(--primary-color3);
	font-size: 14px;
	gap: 10px;
    display: flex !important;
    align-items: center;
}

.homeIcon:hover{
	background-color: var(--primary-color3);
	color: var(--primary-color);
}
.homeIcon:hover .HeaderNav-Msg-Btn{
	color: var(--primary-color);
}
.helpButton{
	position: fixed;
	bottom: 100px;
	right: 20px;
	z-index: 9;
}

/* button.HeaderNav-Msg-Btn {
	border: none;
	height: 50px;
	display: inline-flex;
	padding: 12px 8px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 8px;
	background: var(--primary-color3);
	color: var(--primary-color);
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
} */

.HeaderNav-Right-Inner .btnIcon {
	font-size: 14px;
}

.HeaderNav-Profile-Btn {
	width: 50px;
	height: 50px;
	flex-shrink: 0;
	border-radius: 8px;
}

.mob-btn-box {
	display: flex;
	align-items: center;
	gap: 10px;
}

.mobloginIcon {
	display: none;
}

img.Profile-Img {
	width: 50px;
	height: 50px;
}

.Menu-List-1.active {
	/* background: var(--primary-color3) !important; */
	border-bottom: 5px solid var(--primary-color3) !important;
}

/* .Menu-List-1.active path {
    fill: var(--primary-color) !important;
}

.Menu-List-1.active span.Menu-List-Text {
    color: var(--primary-color) !important;
}  */

/* .HeaderNav-Menu-Inner button svg{
    width: 27px;
    height: 27px;
} */

button.toggle-btn {
	border: none;
	height: 40px;
	display: inline-flex;
	padding: 8px 10px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	background: #fff;
	display: none;
}

button.toggle-btn img {
	display: block;
}

.shareIcon {
	font-size: 20px;
}

.Profile-btn {
	padding: 10px;
	border-bottom: 1px solid #92c0f4;
	display: none;
}

.HeaderNav-Profile-Btn.Profile-mobile-view {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	gap: 10px;
	height: auto;
}

/* .HeaderNav-userBox h6 {
	margin-bottom: 0px;
	color: #2d5d9f;
}

.HeaderNav-userBox p {
	margin-bottom: 0px;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 130px;
} */

.HeaderNav-user {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 40px;
	/* margin-right: 150px; */
}

/* .HeaderNav-userBox {
	margin-right: 30px;
} */

.HeaderNav-userBox {
	display: flex;
	/* gap: 20px; */
	font-size: 14px;
}

.HeaderNav-userBox__col {
	max-width: 55%;
}

.HeaderNav-userBox__row {
	color: var(--primary-color3);
	display: flex;
	column-gap: 5px;
}

.HeaderNav-userBox__value {
	color: var(--primary-color2);
	margin-left: auto;
	max-width: 30ch;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.HeaderNav-userBox__col--name .HeaderNav-userBox__value {
	margin-left: 0;
}

.HeaderNav-userBox ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	column-gap: 30px;
	row-gap: 0;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.HeaderNav-userBox ul li {
	white-space: nowrap;
}

.HeaderNav-userBox dl {
	margin: 0;
	display: flex;
	gap: 5px;
}

.HeaderNav-userBox dl dt {
	font-weight: 600;
	margin-left: 20px;
}

.HeaderNav-userBox dl dd {
	margin-bottom: 0;
}

.HeaderNav-addipr {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-right: 10px;
}

.HeaderNav-addipr__btn:disabled {
	opacity: 0.5;
	cursor: wait;
}

.HeaderNav-addipr__btn:disabled:hover {
	background-color: var(--primary-color4);
	color: var(--primary-color3);
}

.HeaderNav-auth {
	display: flex;
	align-items: center;
	gap: 20px;
	position: relative;
	font-size: 14px;
}

.HeaderNav-auth__welcome {
	line-height: 1.2;
	text-align: right;
}

.HeaderNav-auth__welcome.uuid {
	position: absolute;
	top: calc(100% + 30px);
	right: 0;
	color: var(--primary-color2);
}

.HeaderNav-auth__username {
	display: block;
	font-weight: 700;
	white-space: nowrap;
	color: var(--primary-color3);
}

span.Profile-Text {
	color: var(--primary-color1);
	/* font-family: Inter; */
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

button.close-btn {
	float: right;
	height: auto;
	border: none;
	padding: 15px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	background: #e7ebf7;
	color: #2d5d9f;
	font-weight: bold;
	margin-left: auto;
}

.popup {
	position: relative;
	display: inline-block;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* The actual popup */
.popup .popuptext {
	visibility: visible;
	width: 370px;
	background-color: #555;
	color: #fff;
	text-align: left;
	border-radius: 6px;
	padding: 12px;
	position: absolute;
	z-index: 10;
	right: -60px;
	top: 82px;
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.95);
	color: #2d5d9f;
	/* font-family: Inter; */
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.6;
	box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
}

/* Toggle this class - hide and show the popup */
.popup .show {
	visibility: visible;
	-webkit-animation: fadeIn 1s;
	animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

p.date-meg {
	/* float: right; */
	margin-bottom: 0px;
	margin-top: 5px;
	color: #538ccc;
	/* font-family: Inter; */
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4;
	text-align: right;
}

.bounty_divider {
	background-color: var(--primary-color3);
	content: "";
	height: 34px;
	width: 2px;
	margin: 0 10px;
}
.login__main__block{
	width: 100vw;
	height: 100vh;
	background: var(--primary-color3);
}
.logo_box{
	margin-bottom: 20px;
}

.login-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* background: linear-gradient(135deg, #78FFD6, #FF609E); */
	background: var(--primary-color);
	width: 500px;
	padding: 30px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	text-align: center;
	border-radius: 10px;
	overflow: hidden;
	z-index: 9999;
}

.login-popup .login__title {
	font-size: 20px;
	margin-bottom: 20px;
	color: var(--primary-color3);
}
.login-popup .login__title{
	/* text-align: left; */
	/* margin-left: 0px; */
}
.login__title__block{
	/* text-align: left; */
	margin-bottom: 20px;
	
}

.HeaderNav-logo__block {
    margin: 15px 0px 40px;
}

.input-container {
	margin-bottom: 20px;
	display: flex;
	gap: 15px;
	align-items: center;
}

.enter-button {
	margin-top: 20px;
}

.login-popup__submit-button {
	padding: 15px;
	min-width: 150px;
	background-color: var(--primary-color3);
	color: var(--primary-color);
	font-weight: 500;
}

.form-label {
	min-width: 100px;
	text-align: left;
}

label {
	display: block;
	margin-bottom: 10px;
	font-size: 1rem;
	color: #111010;
}

input {
	width: 100%;
	padding: 10px;
	border: none;
	border-radius: 8px;
	background: var(--primary-color4);
}

input:focus {
	outline: none;
}

.closeIcon {
	position: absolute;
	right: 20px;
	top: 20px;
	color: #2d5d9f;
	font-size: 25px;
	cursor: pointer;
}

button,
.button {
	border: none;
	/* height: 50px; */
	display: inline-flex;
	padding: 10px 15px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 5px;
	background-color: var(--primary-color4);
	color: var(--primary-color3);
	white-space: nowrap;
	/* font-family: Inter; */
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	transition: all 0.25s ease-in;
}

button:hover,
.button:hover {
	background-color: var(--primary-color3);
	color: var(--primary-color);
}

.close-button {
	background-color: #ff4545;
	margin-left: 10px;
}

.modal-header {
	border-bottom: 0px;
}

.modal-footer {
	border-top: 0px;
	justify-content: center;
}

.btn-primary {
	color: #fff;
	background-color: var(--primary-color3);
	border-color: var(--primary-color3);
}

.btn-primary:hover {
	background: var(--primary-color2);
	border-color: var(--primary-color2);
}

.modal-body a {
	color: #000;
	word-break: break-all;
	text-decoration: none;
}

.form-select:focus {
	border-color: none;
	outline: 0;
	box-shadow: none;
}

select::-webkit-scrollbar {
	width: 5px;
}

select::-webkit-scrollbar-thumb {
	background-color: #808080;
	border-radius: 5px;
}

select::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

.spanIpr {
	padding: 8px;
	border-radius: 4px;
	font-weight: bold;
	color: #333;
}

.spanIprForText {
	/* padding: 8px; */
	/* border-radius: 4px; */
	font-weight: 500;
	color: var(--primary-color3);
}

.copyUrlTextarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 8px;
    /* background: var(--primary-color4); */
    height: 150px;
    resize: none;
	font-size: 14px;
	border: 1px solid var(--primary-color4);
}

.copyUrlTextarea:focus-visible {
    outline: none;
}

.showIprBtn{
	margin-right: 10px;
}

.print-button.generating {
	background-color: #666;
	cursor: wait;
}
.fa-spin {
	animation: spin 1s linear infinite;
}
@keyframes spin {
	100% {
			transform: rotate(360deg);
	}
}
