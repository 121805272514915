:root {
	--primary-color: #ffffff;
	--primary-color1: #000000;
	--primary-color2: #538ccc;
	/* --primary-color3: #2d5d9f; */
	--primary-color3: #5869ac; 
	--primary-color4: #e7ebf7;
	--primary-color5: #92c0f4;
	/* --primary-color6: #5869ac; */
}

html {
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
}

* {
	box-sizing: border-box;
}

.overlay::after {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.65);
	z-index: 2;
	cursor: pointer;
	content: "";
	display: block;
}

.button-spinner {
	--size: 24px;
	--offset-r: calc(var(--size) * -1.5);
	--offset-l: 0;
	--opacity: 0;
	position: relative;
	display: inline-flex;
	height: var(--size);
	width: var(--size);
	margin-top: calc(var(--size) * -0.5);
	margin-right: var(--offset-r);
	margin-bottom: calc(var(--size) * -0.5);
	margin-left: var(--offset-l);
	box-sizing: border-box;
	border: 0.125em solid rgba(255, 255, 255, 0.333);
	border-top-color: white;
	border-radius: 50%;
	opacity: var(--opacity);
	transition: 0.25s;
}

.loading .button-spinner {
	--width: 24px;
	--offset-r: 0.333em;
	--offset-l: -0.333em;
	--opacity: 1;
	-webkit-animation: 0.666s load infinite;
	animation: 0.666s load infinite;
}

@-webkit-keyframes load {
	to {
		transform: rotate(360deg);
	}
}
@keyframes load {
	to {
		transform: rotate(360deg);
	}
}
