thead.thead-dark {
	background-color: var(--primary-color3);
	color: var(--primary-color);
}

.dropdown__section {
	margin-top: 30px;
	display: flex;
	align-items: center;
	gap: 10px;
}
.viewer__content {
	padding: 50px 0;
}

.viewer__title {
	color: var(--primary-color3);
}

.viewer__table {
	padding: 10px;
}
