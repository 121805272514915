.Content-Section {
	height: calc(100vh - 210px);
	width: 100%;
}

.notLoggedIn .Content-Section,
.addIprData .Content-Section,
.sharedLink .Content-Section {
	height: calc(100vh - 150px);
}
