/* DragDrop.css */
.addIprInput.drop-zone {
	border: 2px dashed #4a90e2;
	border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	width: 300px; /* Adjust as needed */
	height: 200px; /* Adjust as needed */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.addIprInput.drop-zone p {
	margin: 0;
	font-size: 16px;
	color: #4a90e2;
}

.ipr-popup {
	position: fixed;
	top: 50%;
	/* left: 89%; */
	right: 20px;
	transform: translate(0, -50%);
	/* background: linear-gradient(135deg, #78FFD6, #FF609E); */
	background: var(--primary-color);
	width: 320px;
	max-width: 100%;
	padding: 20px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	text-align: center;
	border-radius: 10px;
	overflow: hidden;
	z-index: 9999;
}

.ipr-popup h2 {
	text-align: left;
	font-size: 20px;
	margin-bottom: 30px;
	color: #2d5d9f;
}
