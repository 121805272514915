@media screen and (min-width: 320px) and (max-width: 1279px) {
	.HeaderNav-Menu {
		position: absolute;
		top: 0;
		left: -250px;
		width: 250px;
		height: 100vh;
		overflow: auto;
		background: #fff;
		z-index: 9;
		display: block;
		transition: all 0.25s ease-in;
	}
	.HeaderNav-Menu-Inner {
		display: block !important;
		/* padding: 10px; */
	}
	.HeaderNav-Menu-Inner button {
		width: 100%;
		flex-direction: row;
		justify-content: flex-start;
		/* margin-bottom: 16px; */
		gap: 10px;
		height: auto;
		border-bottom: 0;
		padding: 10px 15px;
	}
	.HeaderNav-Menu-Inner button.active {
		background-color: var(--primary-color4);
		border-right: 5px solid var(--primary-color3);
		border-bottom: 0 !important;
	}
	.HeaderNav-Msg {
		/* position: fixed;
		bottom: 12px;
		right: 12px;
		z-index: 10; */
	}
	.HeaderNav-section {
		/* justify-content: center; */
		/* position: relative;
		margin-bottom: 50px; */
	}
	.HeaderNav-dropdown {
		position: absolute;
		/* top: 100%; */
		left: 0;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #e7ebf7;
		padding: 5px;
	}
	.HeaderNav-section {
		/* height: 80px; */
	}
	.HeaderNav-logo img {
		/* max-width: 125px;
		height: auto; */
	}
	.Content-Section {
		/* height: calc(100vh - 200px); */
		/* width: 100%; */
		/* margin-top: 50px; */
	}
	.HeaderNav-Profile {
		display: none;
	}
	/* select.form-select {
		height: 40px;
		padding: 8px 10px;
		background-color: #fff;
	}
	select.form-select.speed-select-dropdown {
		background-color: #e7ebf7;
	} */
	.Footer-Section-ButtonAction {
		gap: 10px;
	}
	.Footer-Section-ButtonAction {
		flex-wrap: wrap;
	}
	button.toggle-btn {
		display: block;
	}
	button.toggle-btn {
		background-color: transparent;
	}
	.Profile-btn {
		/* display: block; */
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	.popup .popuptext {
		bottom: 60px;
		right: 0px;
		top: unset;
	}
	.bounty_divider {
		/* display: none; */
		width: 100%;
		height: 2px;
		margin: 10px 0;
	}
	.desktopLoginBtn {
		display: none;
	}
	.mobloginIcon {
		display: block;
		color: #2d5d9f;
		font-size: 20px;
	}
	.userBox {
		display: none !;
	}
	.mob-btn-box {
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translate(0%, -50%);
	}
	button.HeaderNav-Msg-Btn.share-btn {
		background-color: transparent;
	}
	.shareIcon {
		color: #2d5d9f;
	}
}
@media screen and (min-width: 320px) and (max-width: 480px) {
	.popup .popuptext {
		width: 250px;
		height: 300px;
		overflow: auto;
	}
	.HeaderNav-userBox__value{
		max-width: 12ch;
	}
	.Footer-Section-ButtonAction{
		gap: 5px;
	}

	.viewerleft {
		width: 100% !important;
		height: 100%;
		border-right: 1px solid #fff;
	}
	.viewerleftSplit {
		height: 50% !important;
	}

	.viewerright {
		width: 100% !important;
		height: 50%;
		display: none;
	}
	.ProductViewer {
		display: block;
	}
	.input-container {
		display: block;
	}
}

@media screen and (min-width: 320px) and (max-width: 991px) {
	.Footer-Section-CountBtn {
		max-width: calc(100vw - 340px);
	}
	.Footer-Section {
		height: 70px;
	}

	.Content-Section {
		/* height: calc(100vh - 200px); */
		/* width: 100%; */
		/* margin-top: 50px; */
	}
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
	.Footer-Section-CountBtn {
		max-width: calc(100vw - 340px);
	}
}

@media screen and (min-width: 320px) and (max-width: 600px) {
	.Footer-Section {
		height: 130px;
	}
	.Footer-Section-CountBtn {
		max-width: calc(100vw - 40px);
	}
	.Content-Section {
		/* height: calc(100vh - 260px); */
		/* width: 100%; */
		/* margin-top: 50px; */
	}

	.sharedLink .Content-Section {
		height: calc(100vh - 210px);
	}
	.login-popup {
		width: 310px;
	}
	.input-container{
		display: block;
	}

	/* .HeaderNav-Msg {
		bottom: 80px;
	} */
}

@media screen and (min-width: 1760px) {
	.HeaderNav-user {
		position: absolute;
		margin: 0 auto;
		left: 0;
		right: 0;
		max-width: 600px;
	}
}

@media screen and (min-width: 1440px) {
	.notLoggedIn .withEditor .HeaderNav-Menu {
		padding-left: 50px;
	}
}

@media screen and (min-width: 1280px) {
	.HeaderNav-Right {
		max-width: calc(100% - 190px);
		margin-left: auto;
	}

	.HeaderNav-Menu {
		width: 100%;
		margin-bottom: -10px;
	}

	.shareLink .HeaderNav-Menu {
		width: auto;
		margin: 0 auto -10px;
		padding-right: 155px;
	}

	.notLoggedIn .withEditor .HeaderNav-logo {
		order: 0;
	}

	.notLoggedIn .withEditor .HeaderNav-Menu {
		width: auto;
		/* padding-left: 50px; */
		order: 1;
	}

	.notLoggedIn .withEditor .HeaderNav-Right {
		order: 2;
		margin-left: 0;
	}

	.HeaderNav-userBox__col:not(:last-child) {
		border-right: 2px solid var(--primary-color3);
		margin-right: 15px;
		padding-right: 15px;
	}
}

@media screen and (max-width: 1440px) {
	.HeaderNav-user,
	.HeaderNav-userBox ul {
		column-gap: 15px;
	}

	.HeaderNav-user {
		margin-right: 0;
	}
}

@media screen and (max-width: 1279px) {
	.HeaderNav-section.withEditor {
		/* margin-bottom: 50px; */
		padding-bottom: 60px;
	}

	.HeaderNav-Right {
		width: 100%;
	}

	.addIprData .HeaderNav-Right {
		width: auto;
	}

	.HeaderNav-Right-Inner .btnIcon {
		font-size: 16px;
	}

	.HeaderNav-share,
	.HeaderNav-auth {
		position: absolute;
		top: 22px;
	}

	.addIprData .HeaderNav-auth {
		position: static;
	}

	.HeaderNav-share {
		right: 50px;
	}

	.HeaderNav-auth {
		gap: 10px;
		right: 10px;
	}

	.HeaderNav-share > button,
	.HeaderNav-auth > button {
		font-size: 0;
		gap: 0;
		background: transparent;
		color: var(--primary-color3);
		padding: 10px;
	}
	.homeIcon > button{
		background-color: transparent;
		padding: 10px;
	}

	.HeaderNav-user {
		width: 100%;
		margin-top: 10px;
		margin-right: 0;
	}

	.HeaderNav-userBox {
		margin-right: 0;
		flex-direction: column;
	}

	.HeaderNav-userBox__col {
		max-width: inherit;
		display: flex;
		gap: 15px;
	}

	.HeaderNav-userBox ul {
		column-gap: 10px;
		font-size: 14px;
	}

	.HeaderNav-download .download {
		height: auto;
	}

	.HeaderNav-auth__welcome.uuid {
		display: none;
	}

	.HeaderNav-section.withEditor.shareLink {
		padding-bottom: 10px;
		margin-bottom: 0 !important;
	}

	.Content-Section {
		height: calc(100vh - 273px);
	}

	.addIprData .Content-Section,
	.notLoggedIn .Content-Section {
		height: calc(100vh - 200px);
	}

	.HeaderNav-Msg {
		position: fixed;
		bottom: 85px;
		right: 12px;
		z-index: 2;
	}
	.homeIcon{
		position: absolute;
    	top: 22px;
		right: 85px;
		background-color: transparent;
	}
	.shareText{
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.HeaderNav-userBox ul {
		justify-content: flex-start;
	}

	.addIprData .HeaderNav-Right {
		width: 100%;
	}

	.addIprData .HeaderNav-auth {
		position: absolute;
	}

	.HeaderNav-addipr {
		width: 100%;
		margin: 10px 0 0;
		justify-content: center;
	}
	.HeaderNav-download{
		position: absolute;
		right: 20px;
		bottom: 60px;
	}
}

@media screen and (max-width: 640px) {
	.Content-Section {
		height: calc(100vh - 333px);
	}

	.notLoggedIn .Content-Section {
		height: calc(100vh - 260px);
	}

	.addIprData .Content-Section {
		height: calc(100vh - 310px);
	}

	.HeaderNav-Msg {
		bottom: 150px;
	}
}

@media screen and (min-width: 1280px) and (max-width: 1370px) {
	.shareText{
		display: none !important;
	}
	.share-btn{
		padding: 11px 12px !important;
	}
	.homeIcon{
		background-color: var(--primary-color4);
	}	
	.homeIcon .HeaderNav-Msg-Btn{
		padding: 11px 12px !important;
	}
}

@media screen and (min-width: 320px) and (max-width: 700px) {
	.helpButton {
		bottom: 144px;
	}
}
